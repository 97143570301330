import { Getter, ItemMenu, ItemMenuConfig, Params } from './menu.types';

export const itensMenuConfig: ItemMenuConfig[] = [
  {
    marcas: ['estacio', 'ibmec', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA', 'ESPECIALIZAÇÃO', 'FORMAÇÃO PROFISSIONAL NÍVEL TÉCNICO'],
    item: 'home',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'ibmec', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA', 'ESPECIALIZAÇÃO', 'FORMAÇÃO PROFISSIONAL NÍVEL TÉCNICO'],
    item: 'disciplinas',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA', 'ESPECIALIZAÇÃO'],
    item: 'biblioteca-virtual',
    habilitado: true,
  },
  {
    marcas: ['ibmec'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA'],
    item: 'biblioteca-virtual',
    habilitado: true,
  },
  {
    marcas: ['estacio'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['FORMAÇÃO PROFISSIONAL NÍVEL TÉCNICO'],
    item: 'biblioteca-virtual',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'wyden'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA', 'ESPECIALIZAÇÃO'],
    item: 'empregabilidade',
    habilitado: true,
  },
  {
    marcas: ['idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO'],
    item: 'empregabilidade',
    habilitado: true,
  },
  {
    marcas: ['estacio'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['FORMAÇÃO PROFISSIONAL NÍVEL TÉCNICO'],
    item: 'empregabilidade',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'ibmec', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA', 'ESPECIALIZAÇÃO'],
    item: 'calendario-academico',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'ibmec', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA', 'ESPECIALIZAÇÃO'],
    item: 'ajuda',
    habilitado: true,
  },
  {
    marcas: ['estacio'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['FORMAÇÃO PROFISSIONAL NÍVEL TÉCNICO'],
    item: 'ajuda',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'wyden', 'ibmec'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA'],
    item: 'exercicios',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'wyden'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA', 'ESPECIALIZAÇÃO'],
    item: 'avaliacoes',
    habilitado: true,
  },
  {
    marcas: ['estacio'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['FORMAÇÃO PROFISSIONAL NÍVEL TÉCNICO'],
    item: 'avaliacoes',
    habilitado: true,
  },
  {
    marcas: ['ibmec'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['ESPECIALIZAÇÃO'],
    item: 'avaliacoes',
    modalidade: ['TOTAL EAD'],
    habilitado: true,
  },
  {
    marcas: ['estacio', 'ibmec', 'wyden'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA', 'ESPECIALIZAÇÃO'],
    item: 'campus-sia',
    habilitado: true,
  },
  {
    marcas: ['estacio'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['FORMAÇÃO PROFISSIONAL NÍVEL TÉCNICO'],
    item: 'campus-sia',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'ibmec', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA', 'ESPECIALIZAÇÃO', 'FORMAÇÃO PROFISSIONAL NÍVEL TÉCNICO'],
    item: 'acessibilidade',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'ibmec', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA', 'ESPECIALIZAÇÃO'],
    item: 'certificacoes',
    habilitado: true,
  },
  {
    marcas: ['estacio', 'wyden', 'idomed'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA', 'ESPECIALIZAÇÃO', 'FORMAÇÃO PROFISSIONAL NÍVEL TÉCNICO'],
    item: 'cursos-complementares',
    habilitado: true,
  },
];

export const getItensMenuConfig: Getter<Params, ItemMenu[]> = ({ ambiente, marca }: Params) => {
  return itensMenuConfig
    .filter((item) => item.marcas.includes(marca) && item.ambientes.includes(ambiente) && item.habilitado)
    .map((item) => ({
      item: item.item,
      tipoCursoAluno: item.tipoCurso,
      modalidadeAluno: item.modalidade,
    }));
};
