import { AjudaLogin } from '../../types';

const contruirAjudaLogin = (): AjudaLogin => {
  return {
    introducao: {
      titulo: 'Está com dificuldades para acessar? A gente te ajuda!',
      paragrafo: [],
    },
    etapa: [
      {
        titulo: 'Como realizar meu primeiro acesso?',
        paragrafo: [
          {
            subtitulo: 'Qual meu login e senha para primeiro acesso?',
          },
          { label: `Para acessar, você precisa utilizar seu <b>e-mail de estudante</b> e sua <b>senha</b>.` },
          {
            label: `O <b>e-mail de estudante</b> dá acesso a todas as funcionalidades do ambiente digital que vão te acompanhar ao longo do seu curso. Esse e-mail possui o seguinte formato:`,
          },
          { destaque: `[nº da matrícula]@alunos.[nome da instituição].edu.br` },
          {
            label: `<span aria-hidden="true" tabIndex={-1}><b>Exemplo:</b> Meu nome é Rafael, sou aluno da Unifavip e minha matrícula é 201900000001.<br>Meu e-mail de estudante é: 201900000001@alunos.unifavip.edu.br.</span>
            <span className="somenteLeitorDeTela absoluteSpan"><b>Exemplo:</b> Meu nome é Rafael, sou aluno da Unifavip e minha matrícula é 2 0 1 9 0 0 0 0 0 0 0 1. Meu e-mail de estudante é: 2 0 1 9 0 0 0 0 0 0 0 1 @alunos.unifavip.edu.br</span>`,
          },
          {
            destaque: `Você pode consultar seu e-mail de estudante no aplicativo Integrees, seguindo o passo a passo abaixo:`,
          },
          {
            label: `<a class="ajudaEtapaLink" href="https://onelink.to/integrees" target="_blank" noopener noreferrer>Baixe aqui</a> o aplicativo Integrees na loja de apps do seu celular.`,
          },
          {
            label:
              'Na página inicial do aplicativo, clique em “Esqueci meu e-mail de acesso”, indique seu CPF, data de nascimento e prossiga para consultar seu e-mail.',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/wyden/01-integrees-app.png',
                alt: 'Página de login do app integrees',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/wyden/01-integrees-app.png',
                alt: 'Página de login do app integrees',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label:
              'Se você já acessou seu e-mail de estudante e <b>alterou a sua senha</b>, pode continuar fazendo seu login normalmente. Mas se este for o seu primeiro acesso, utilize a <b>senha padrão</b>, que possui o seguinte formato:',
          },
          {
            destaque: `Os 6 primeiros dígitos do seu CPF + @ + [as duas primeiras letras do seu nome, sendo a primeira maiúscula e a segunda minúscula].`,
          },
          {
            label: `<span aria-hidden="true" tabIndex={-1}>Exemplo: Meu nome é Rafael, e o meu CPF é 123.456.789-10, e minha senha padrão é: 123456@ra.</span>
            <span className="somenteLeitorDeTela absoluteSpan">Exemplo: Meu nome é Rafael, e o meu CPF é 1 2 3 4 5 6 7 8 9 1 0, e minha senha padrão é: 1 2 3 4 5 6@ r a</span>`,
          },
          {
            label: 'Observe abaixo como efetuar seu acesso:',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/wyden/02-login-wyden.png',
                alt: 'Página de login da Wyden',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/wyden/02-login-wyden.png',
                alt: 'Página de login da Wyden',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label: `<span>Para garantir maior segurança, indicamos que você&nbsp;<a class="ajudaEtapaLink" href="https://account.activedirectory.windowsazure.com/ChangePassword.aspx" target="_blank" noopener noreferrer>altere sua senha</a>&nbsp;após seu primeiro acesso.</span>`,
          },
          {
            label: `Se ainda tiver dúvidas, veja o vídeo que preparamos para te ajudar:`,
          },
          {
            video: { url: 'https://www.youtube.com/embed/jH7woNoH1Ao' },
          },
        ],
      },
      {
        titulo: 'Meu acesso ainda não foi liberado. Como prosseguir?',
        paragrafo: [
          {
            subtitulo: 'Sou calouro(a)',
          },
          { label: 'Neste caso, o acesso é exclusivo para alunos matriculados.' },
          {
            label:
              'Para receber o acesso à plataforma de aprendizagem, você precisa cumprir todos os critérios abaixo:',
          },
          { destaque: '- Matrícula ativa;' },
          { destaque: '- Documentação para início de curso entregue;' },
          { destaque: '- Confirmação de início do curso.' },
          {
            label:
              '<span>Após cumprir os critérios, sua matricula será gerada em até&nbsp;<b>24 horas</b>&nbsp;e seu acesso será criado!</span>',
          },
          {
            label:
              '<span>Caso você ainda não seja aluno(a) ou esteja com o processo de matrícula em andamento, acompanhe o evolução do seu caso no&nbsp;<a class="ajudaEtapaLink" href="https://estacio.br/candidato" target="_blank" noopener noreferrer>Portal do Candidato</a>&nbsp;.</span>',
          },
          {
            subtitulo: 'Sou veterano(a)',
          },
          {
            label:
              'Seu acesso permanece liberado entre seus períodos, mas você só será capaz de ver suas disciplinas quando realizar a rematricula.',
          },
          {
            label:
              '<span>Caso você já tenha completado sua rematricula, aguarde até&nbsp;<b>24 horas</b>&nbsp;para atualização na sua Sala de Aula Virtual.</span>',
          },
        ],
      },
      {
        titulo: 'Esqueci minha senha. O que fazer?',
        paragrafo: [
          {
            label:
              '<span>Na página de login da SAVA, clique no botão a&nbsp;<a class="ajudaEtapaLink" href="https://estudante.wyden.com.br/login" target="_blank" noopener noreferrer>Esqueci Minha Senha</a>&nbsp.</span>',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/wyden/02-login-wyden.png',
                alt: 'Página de login aonde tem o link para recuperar a senha do usuario',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/wyden/02-login-wyden.png',
                alt: 'Página de login aonde tem o link para recuperar a senha do usuario',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label: 'Informe seu <b>e-mail de estudante</b> e insira os caracteres da imagem.',
          },
          {
            label: 'Feito isso, clique em avançar.',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/wyden/03-quem-e-voce.png',
                alt: 'Página de recuperação de senha, etapa de identificação do usuário.',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/wyden/03-quem-e-voce.png',
                alt: 'Página de recuperação de senha, etapa de identificação do usuário.',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label:
              'Na próxima página, escolha por qual meio de contato você deseja receber o código de verificação: e-mail alternativo, SMS ou ligação para o celular.',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/wyden/04-metodo-contato.png',
                alt: 'Página de recuperação de senha, etapa para escolher o método de contato com o usuário.',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/wyden/04-metodo-contato.png',
                alt: 'Página de recuperação de senha, etapa para escolher o método de contato com o usuário.',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label: 'Após receber o código, utilize-o para a verificação prosseguir.',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/wyden/05-verificacao-contato.png',
                alt: 'Página de recuperação de senha, etapa que faz a verificação do usuário',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/wyden/05-verificacao-contato.png',
                alt: 'Página de recuperação de senha, etapa que faz a verificação do usuário',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label: 'Pronto! Agora é só definir uma nova senha.',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/wyden/06-nova-senha.png',
                alt: 'Página de recuperação de senha, etapa para criar nova senha',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/wyden/06-nova-senha.png',
                alt: 'Página de recuperação de senha, etapa para criar nova senha',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label:
              'Você também pode alterar a senha do seu e-mail de estudante pelo Portal do Aluno (SIA). Para isso, acesse o SIA com sua matrícula e senha e siga o passo a passo:',
          },
          {
            label:
              'Clique no Menu Lateral > E-mail de Estudante > Atualização de Senha e Benefícios > Atualizar Senha.',
          },
          {
            label: 'Atualize a senha respeitando os requisitos necessários.',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/wyden/07-atualizacao-senha-wyden.gif',
                alt: 'Página da Wyden para atualização da senha.',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/wyden/07-atualizacao-senha-wyden.gif',
                alt: 'Página da Wyden para atualização da senha.',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
        ],
      },
    ],
    conclusao: {
      titulo: 'Ainda precisa de ajuda para acessar?',
      paragrafo: [
        'Entre em contato com um de nossos atendentes no seguinte telefone.',
        '<b>Fale com a gente: 0800 771 5001</b>',
      ],
    },
  };
};

export const AJUDA_LOGIN: AjudaLogin = contruirAjudaLogin();
