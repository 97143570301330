import { Ambiente } from '../../types';

interface ChatScriptSource {
  ambientes: Ambiente[];
  url: string;
}

const chatScriptSources: ChatScriptSource[] = [
  {
    ambientes: ['local', 'dev', 'hml'],
    url: 'https://yduqs-chat-lib-dev.yduqs.com.br/chat-lib.js',
  },
  {
    ambientes: ['prd'],
    url: 'https://yduqs-chat-lib-prd.yduqs.com.br/chat-lib.js',
  },
];

export const getChatScriptSource = (ambiente: Ambiente): string => {
  const chatScriptSource = chatScriptSources.find((source) => source.ambientes.includes(ambiente));

  return chatScriptSource?.url ?? '';
};
