import { DateTime } from 'luxon';
import { Avaliacao, AvaliacaoConfig, Getter, Params } from './avaliacoes.types';

export const avaliacoesConfig: AvaliacaoConfig[] = [
  {
    marcas: ['estacio', 'wyden'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipo: 'simulado',
    dataInicio: DateTime.fromISO('2022-08-29T00:00:00'),
    dataFim: DateTime.fromISO('2022-11-08T00:00:00'),
  },
  {
    marcas: ['estacio', 'wyden'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipo: 'av',
    dataInicio: DateTime.fromISO('2022-09-06T00:00:00'),
    dataFim: DateTime.fromISO('2022-11-22T00:00:00'),
  },
  {
    marcas: ['estacio', 'wyden'],
    ambientes: ['local', 'dev', 'prd', 'hml'],
    tipo: 'avs',
    dataInicio: DateTime.fromISO('2022-11-26T00:00:00'),
    dataFim: DateTime.fromISO('2022-12-06T00:00:00'),
  },
];

export const getAvaliacoesConfig: Getter<Params, Avaliacao[]> = ({ ambiente, marca }: Params) => {
  return avaliacoesConfig
    .filter((item) => item.marcas.includes(marca) && item.ambientes.includes(ambiente))
    .map((item) => {
      return {
        tipo: item.tipo,
        dataInicio: item.dataInicio,
        dataFim: item.dataFim,
      };
    });
};
