import { AjudaLogin } from '../../types';

const contruirAjudaLogin = (): AjudaLogin => {
  return {
    introducao: {
      titulo: 'Esse acesso é exclusivo para alunos matriculados.',
      paragrafo: [
        'Você já possui acesso a sua plataforma de aprendizagem caso você já seja nosso aluno seguindo os critérios abaixo:',
        '- Matrícula ativa ',
        '- Entregue documentação para início de curso',
        '- Seu curso esteja confirmado ',
        'Caso ainda não esteja com as 3 situações mencionadas acima, vá até o SIA e realize sua matrícula.',
        'Mas caso já seja aluno se liga no passo a passo descrito abaixo para acessar.',
      ],
    },
    etapa: [
      {
        titulo: 'Como realizar meu primeiro acesso?',
        paragrafo: [
          {
            subtitulo: 'Qual meu login e senha para primeiro acesso?',
          },
          {
            label: `<span>Para realizar seu primeiro acesso, você precisa utilizar seu <b>e-mail de estudante</b> e sua <b>senha</b>.</span>`,
          },
          {
            label: `<span>O <b>e-mail de estudante</b> dá acesso a todas as funcionalidades do ambiente digital que vão te acompanhar ao longo do seu curso. Esse e-mail possui o seguinte formato:<span>`,
          },
          { destaque: `[número-da-sua-matrícula]@alunos.ibmec.edu.br` },
          {
            label: `<span aria-hidden="true" tabIndex={-1}><b>Exemplo</b>: Meu nome é Rafael, sou aluno Ibmec e minha matrícula é 201900000001.</br> Meu e-mail de estudante é: 201900000001@alunos.ibmec.edu.br</span><span className="somenteLeitorDeTela absoluteSpan">Exemplo: Meu nome é Rafael, sou aluno Ibmec e minha é matrícula 2 0 1 9 0 0 0 0 0 0 0 1<br />Meu email de estudante é: 2 0 1 9 0 0 0 0 0 0 0 1@alunos.ibmec.edu.br</span>`,
          },
          {
            destaque: `<span>Você pode consultar seu e-mail de estudante no aplicativo Meu Ibmec, seguindo o passo a passo abaixo: </br><a class="ajudaEtapaLink" href="https://onelink.to/meuibmec" target="_blank" noopener noreferrer>Baixe aqui</a> o aplicativo Meu Ibmec na loja de apps do seu celular.</span>`,
          },
          {
            label: `<span>Na página inicial do aplicativo, clique em “Esqueci meu e-mail de acesso”, indique seu CPF, data de nascimento e prossiga para consultar seu e-mail.<span>`,
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/ibmec/01.jpg',
                alt: 'Tela inicial do aplicativo Meu Ibmec',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/ibmec/01.jpg',
                alt: 'Tela inicial do aplicativo Meu Ibmec',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label: `<span>Se você já acessou seu e-mail de estudante e <b>alterou a sua senha</b>, pode continuar fazendo seu login normalmente. Mas se este for o seu primeiro acesso, utilize a <b>senha padrão</b>, que possui o seguinte formato:</span>`,
          },
          {
            destaque: `6 primeiros dígitos do seu CPF + @ + As duas primeiras letras do seu nome, sendo a primeira maiúscula e a segunda minúscula.`,
          },
          {
            label: `<span aria-hidden="true" tabIndex={-1}>Exemplo: Meu nome é Rafael, matrícula 201900000001 e meu CPF é 123.456.789-10<br />Minha senha padrão é: 123456@Ra</span><span className="somenteLeitorDeTela absoluteSpan">Exemplo:  Meu nome é Rafael, matrícula 2 0 1 9 0 0 0 0 0 0 0 1 e meu cpf é 123 456 789-10<br /><br />Minha senha padrão é 123 456@Ra</span>`,
          },
          {
            label: `Observe abaixo como efetuar o seu acesso:`,
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/ibmec/02.gif',
                alt: 'Tela de login IBMEC',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/ibmec/02.gif',
                alt: 'Tela de login IBMEC',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label: `<span>Para garantir maior segurança, indicamos que você&nbsp;<a class="ajudaEtapaLink" href="https://account.activedirectory.windowsazure.com/ChangePassword.aspx" target="_blank" noopener noreferrer>altere sua senha</a>&nbsp;após seu primeiro acesso</span>`,
          },
        ],
      },
      {
        titulo: 'Meu acesso ainda não foi liberado. Como prosseguir?',
        paragrafo: [
          {
            subtitulo: 'Sou calouro(a)',
          },
          {
            label:
              'Neste caso, o acesso é exclusivo para alunos matriculados. Para receber o acesso à plataforma de aprendizagem, você precisa cumprir todos os critérios abaixo:',
          },
          { destaque: '- Matrícula ativa; ' },
          { destaque: '- Documentação para início de curso entregue;' },
          { destaque: '- Confirmação de início do curso.' },
          {
            label:
              '<span>Após cumprir os critérios, sua matricula será gerada em até&nbsp;<b>24 horas</b>&nbsp;e seu acesso será criado!</span>',
          },
          {
            label:
              'Caso você ainda não seja aluna(o) ou esteja com o processo de matrícula em andamento, acompanhe o evolução do seu caso no <b><a href="https://portaldocandidato.ibmec.br/Login.aspx?ReturnUrl=%2F">Portal do Candidato</a>.</b>',
          },
          {
            subtitulo: 'Sou veterano(a)',
          },
          {
            label:
              'Seu acesso permanece liberado entre seus períodos, mas você só será capaz de ver suas disciplinas quando realizar a rematricula.',
          },
          {
            label:
              '<span>Caso você já tenha completado sua rematricula, aguarde até&nbsp;<b>24 horas</b>&nbsp;para atualização na sua Sala de Aula Virtual.</span>',
          },
        ],
      },
      {
        titulo: 'Esqueci minha senha. O que fazer?',
        paragrafo: [
          {
            label:
              '<span>Na página de login da Sala de Aula Virtual Academica (SAVA), clique no botão <b>“Esqueci Minha Senha”<b>:</span>',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/ibmec/03.jpg',
                alt: 'Tela de login IBMEC',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/ibmec/03.jpg',
                alt: 'Tela de login IBMEC',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label:
              '<span>Informe seu <b>e-mail de estudante</b> e insira os caracteres da imagem. Feito isso, clique em avançar.</span>',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/ibmec/esqueci-minha-senha.jpg',
                alt: 'Esqueci Minha Senha',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/ibmec/esqueci-minha-senha.jpg',
                alt: 'Esqueci Minha Senha',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label:
              '<span>Na próxima página, escolha por qual meio de contato você deseja receber o código de verificação: e-mail alternativo, SMS ou ligação para o celular.</span>',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/ibmec/05.png',
                alt: 'Esqueci Minha Senha',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/ibmec/05.png',
                alt: 'Esqueci Minha Senha',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label: '<span>Após receber o código, utilize-o para a verificação prosseguir.</span>',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/ibmec/06.png',
                alt: 'Esqueci Minha Senha',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/ibmec/06.png',
                alt: 'Esqueci Minha Senha',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label: '<span>Pronto! Agora é só definir uma nova senha.</span>',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/ibmec/07.png',
                alt: 'Esqueci Minha Senha',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/ibmec/07.png',
                alt: 'Esqueci Minha Senha',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label:
              'Você também pode alterar a senha do seu e-mail de estudante pelo Portal do Aluno (SIA). Para isso, acesse o SIA com sua matrícula e senha e siga o passo a passo:',
          },
          {
            label:
              '<span aria-hidden="true">Clique no Menu Lateral > E-mail de Estudante > Atualização de Senha e Benefícios > Atualizar Senha<span><span className="somenteLeitorDeTela absoluteSpan">Clique no Menu, depois clique em: E-mail de Estudante. Após isso clique em: Atualização de Senha e Benefícios. Por fim, clique em Atualizar Senha</span>',
          },
          { label: 'Atualize a senha respeitando os requisitos necessários.' },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/ibmec/04.gif',
                alt: 'Esqueci minha senha. O que fazer?',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/ibmec/04.gif',
                alt: 'Esqueci minha senha. O que fazer?',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label:
              '<span><b>Passo 1:</b> na tela principal depois do login clique no menu lateral em&nbsp;<b>”E-mail de Estudante”</b>&nbsp;e depois em&nbsp;<b>"Atualização de Senha E Benefícios".</b></span>',
          },
          {
            label: '<span><b>Passo 2:</b> Crie sua senha seguindo as regras informadas.</span>',
          },
          {
            label: '<span><b>Passo 3:</b> Aceite os Termos de Uso e depois clique em Confirmar.</span>',
          },
        ],
      },
    ],
    conclusao: {
      titulo: 'Ainda precisa de ajuda para acessar?',
      paragrafo: [
        'Entre em contato com um de nossos atendentes nos seguintes telefones.',
        '<b>Fale com a gente: 0800 771 8020</b>',
      ],
    },
  };
};

export const AJUDA_LOGIN: AjudaLogin = contruirAjudaLogin();
