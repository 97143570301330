import { TapumeAvaliacoesConfig } from './tapume-avaliacoes.config.types';

const tapumeAvaliacoesConfig: TapumeAvaliacoesConfig = {
  marcas: ['estacio', 'wyden'],
  periodo: ['2024.3'],
  modalidalidade: ['PRESENCIAL', 'AO_VIVO', 'FLEX_ESPECIAL', 'SEMIPRESENCIAL', 'TOTAL_EAD'],
};

export const getTapumeAvaliacoesConfig = (): TapumeAvaliacoesConfig => {
  return tapumeAvaliacoesConfig;
};
