import { FormacaoSocioemocionalConfig, GetFormacaoSociomeocionalConfig } from './formacao-socioemocional.config.types';

const formacaoSociomeocionalConfig: FormacaoSocioemocionalConfig = {
  marcas: ['estacio', 'wyden', 'unitoledo', 'idomed'],
  tipoCurso: ['GRADUAÇÃO', 'GRADUAÇÃO TECNOLÓGICA'],
  modalidalidade: [],
  codigoCurso: [],
};

export const getFormacaoSociomeocionalConfig: GetFormacaoSociomeocionalConfig = () => {
  return formacaoSociomeocionalConfig;
};
