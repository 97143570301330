import { DetectaNavegador } from './detecta-navegador.config.types';

export const detectaNavegador: DetectaNavegador = () => {
  const userAgent = navigator.userAgent;

  /** Edge */
  if (/Edg|Edge/.test(userAgent)) {
    return 'EDGE';
  }

  /** Firefox */
  if (/Firefox/.test(userAgent)) {
    return 'FIREFOX';
  }

  /** Chrome Android */
  if (/Chrome/.test(userAgent) && /Android/.test(userAgent)) {
    return 'CHROME_MOBILE';
  }

  /** Chrome Desktop */
  if (/Chrome/.test(userAgent)) {
    return 'CHROME_DESKTOP';
  }

  /** Safari IOs */
  if (/Safari/.test(userAgent) && /iPhone|iPad/.test(userAgent)) {
    return 'SAFARI_MOBILE';
  }

  /** Safari Mac */
  if (/Safari/.test(userAgent)) {
    return 'SAFARI_DESKTOP';
  }

  return 'NAVEGADOR_DESCONHECIDO';
};
