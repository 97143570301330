import { DefaultTapumeConfig, GetTapumeConfig, IbmecTapumeConfig } from './tapume-erro-carregamento.config.types';

const defaultConfig = (): DefaultTapumeConfig => {
  return {
    titulo: 'O carregamento da página falhou',
    conteudo: [
      'Desculpe, nem a gente esperava por essa. Que tal limpar o cache do seu navegador e tentar acessar novamente?',
    ],
  };
};

const ibmecConfig = (): IbmecTapumeConfig => {
  return {
    titulo: 'Manutenção em andamento',
    conteudo: [
      'Estamos lidando com uma instabilidade no sistema e já estamos trabalhando para deixar sua experiência na SAVA ainda melhor.',
      'Enquanto isso, acesse o Ambiente de Avaliações por meio do link: [HIPERLINK]',
      'Em breve, a SAVA vai ficar disponível novamente. Boas provas!',
    ],
    hyperlink: 'https://simulado.ibmec.br/alunos/',
  };
};

export const getTapumeConfig: GetTapumeConfig = ({ marca }) => {
  return marca === 'ibmec' ? ibmecConfig() : defaultConfig();
};
