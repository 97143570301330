export const EXTENSOES_VALIDAS_ENVIO_ARQUIVO: string[] = [
  'ai',
  'bmp',
  'csv',
  'dbf',
  'dif',
  'doc',
  'docm',
  'docx',
  'dot',
  'dotm',
  'dotx',
  'emf',
  'gif',
  'jpg',
  'mp4',
  'mpp',
  'mpt',
  'odb',
  'odc',
  'odf',
  'odg',
  'odm',
  'odp',
  'ods',
  'odt',
  'odt',
  'otg',
  'otp',
  'ots',
  'ott',
  'pdf',
  'png',
  'pot',
  'potm',
  'potx',
  'ppa',
  'pps',
  'ppsm',
  'ppsx',
  'ppt',
  'pptm',
  'pptx',
  'psd',
  'rtf',
  'svg',
  'tif',
  'txt',
  'wmf',
  'wmv',
  'xls',
  'xlsb',
  'xlsm',
  'xlsx',
  'xlt',
  'xltm',
  'xltx',
  'xlw',
  'xml',
  'xps',
];
