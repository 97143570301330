import { Brand } from '@aura/contextos-frontend/dist/contexts/config/config.types';
import { OpcaoMenuAjuda } from '../../../../../components/menu/components/ajuda-item-menu/ajuda-item-menu.types';

const URL_CHAT_HELP = 'https://yduqs2020.my.salesforce-sites.com/atlchat?marca=[MARCA]&origem=sava';

const capitalizeString = (str: string): string => {
  return `${str.charAt(0).toUpperCase()}${str.substring(1)}`;
};

const getUrlChatHelpPorMarca = (marca: Brand): string => URL_CHAT_HELP.replace('[MARCA]', capitalizeString(marca));

const blockListsPorMarca = new Map<Brand, { tiposCursos: string[]; modalidades: string[] }>();

blockListsPorMarca.set('estacio', { tiposCursos: ['GRADUACAO', 'GRADUACAO_TECNOLOGICA'], modalidades: ['TOTAL_EAD'] });
blockListsPorMarca.set('idomed', { tiposCursos: ['ESPECIALIZACAO'], modalidades: ['TOTAL_EAD', 'FLEX', 'AO_VIVO'] });
blockListsPorMarca.set('ibmec', { tiposCursos: ['ESPECIALIZACAO'], modalidades: ['TOTAL_EAD', 'FLEX', 'AO_VIVO'] });

const chatHelp = (marca: Brand): OpcaoMenuAjuda => ({
  titulo: 'ChatHelp',
  tagGA: 'chat-help',
  url: getUrlChatHelpPorMarca(marca),
  tipoVisualizacao: 'EXTERNO',
  tipo: 'FAQ',
});

export const getChatHelp = (marca: Brand) => ({
  get: (tipoCurso: string, modalidade: string) => {
    const dadosBlockListMarca = blockListsPorMarca.get(marca);

    if (!dadosBlockListMarca) {
      return chatHelp(marca);
    }

    return dadosBlockListMarca.modalidades.includes(modalidade) && dadosBlockListMarca.tiposCursos.includes(tipoCurso)
      ? null
      : chatHelp(marca);
  },
});
