import { AjudaLogin } from '../../types';

const contruirAjudaLogin = (): AjudaLogin => {
  return {
    introducao: {
      titulo: 'Esse acesso é exclusivo para alunos matriculados.',
      paragrafo: [
        'Você já possui acesso a sua plataforma de aprendizagem caso você já seja nosso aluno seguindo os critérios abaixo:',
        '- Matrícula ativa',
        '- Entregue documentação para início de curso',
        '- Seu curso esteja confirmado ',
        'Caso ainda não esteja com as 3 situações mencionadas acima, vá até o SIA e realize sua matrícula.',
        'Mas caso já seja aluno se liga no passo a passo descrito abaixo para acessar.',
      ],
    },
    etapa: [
      {
        titulo: 'Como realizar meu primeiro acesso?',
        paragrafo: [
          {
            subtitulo: 'Qual meu login e senha para primeiro acesso?',
          },
          {
            label: `<span>Para realizar seu primeiro acesso, você precisa utilizar seu <b>e-mail de estudante</b> e sua <b>senha</b>.</span>`,
          },
          {
            label: `<span>O <b>e-mail de estudante</b> dá acesso a todas as funcionalidades do ambiente digital que vão te acompanhar ao longo do seu curso. Esse e-mail possui o seguinte formato: <b>[Nº da Matrícula]@alunos.[Nome da Instituição].br</b></span>`,
          },
          {
            label: `<span aria-hidden="true" tabIndex={-1}><b>Exemplo:</b> Meu nome é Rafael, sou aluno da Facid e minha matrícula é 201900000001.<br />Meu e-mail de estudante é: 201900000001@alunos.facid.edu.br</span> <span className="somenteLeitorDeTela absoluteSpan">Exemplo: Meu nome é Rafael, sou aluno da Facid e minha matrícula é 2 0 1 9 0 0 0 0 0 0 0 1.<br />Meu e-mail de estudante é: 2 0 1 9 0 0 0 0 0 0 0 1@alunos.facid.edu.br</span>`,
          },
          {
            label: `<span><b>Instituição/Domínio</b></span><br /><b>Estácio</b> - @alunos.estacio.br;<br /><b>Ibmec</b> - @alunos.ibmec.edu.br;<br /><b>Facid</b> - @alunos.facid.edu.br;<br /><b>UniFacid</b> - @alunos.unifacid.edu.br;<br /><b>Fameac</b> - @alunos.fameac.com.br<br />`,
          },
          {
            label: `<span>Se você já acessou seu e-mail de estudante e <b>alterou a sua senha</b>, pode continuar fazendo seu login normalmente. Mas se este for o seu primeiro acesso, utilize a <b>senha padrão</b>, que possui o seguinte formato:</span>`,
          },
          {
            destaque: `[O 6 primeiros dígitos do seu CPF] + @ + [As duas primeiras letras do seu nome, sendo a primeira maiúscula e a segunda minúscula]`,
          },
          {
            label: `<span aria-hidden="true" tabIndex={-1}>Exemplo: Meu nome é Rafael e meu é CPF 123.456.789-10.<br />Minha senha padrão é: 123456@Ra</span> <span className="somenteLeitorDeTela absoluteSpan">Exemplo: Meu nome é Rafael e meu é CPF 123 456 789-10.<br />Minha senha padrão é: 123 456@Ra</span>`,
          },
          {
            label: `Observe abaixo como efetuar seu acesso:`,
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/idomed/01.gif',
                alt: 'Como efetuar o acesso',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/idomed/01.gif',
                alt: 'Como efetuar o acesso',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label: `<span>Para garantir maior segurança, indicamos que você <a class="ajudaEtapaLink" href="https://passwordreset.microsoftonline.com/" target="_blank" noopener noreferrer>altere sua senha</a> após seu primeiro acesso</span>`,
          },
        ],
      },
      {
        titulo: 'Meu acesso ainda não foi liberado. Como prosseguir?',
        paragrafo: [
          {
            subtitulo: 'Sou calouro(a)',
          },
          { label: 'Neste caso, o acesso é exclusivo para alunos matriculados.' },
          {
            label:
              'Para receber o acesso à plataforma de aprendizagem, você precisa cumprir todos os critérios abaixo:',
          },
          { destaque: '- Matrícula ativa; ' },
          { destaque: '- Documentação para início de curso entregue;' },
          { destaque: '- Confirmação de início do curso.' },
          {
            label:
              '<span>Após cumprir os critérios, sua matricula será gerada em até <b>24 horas</b> e seu acesso será criado!</span>',
          },
          {
            label:
              '<span>Caso você ainda não seja aluno(a) ou esteja com o processo de matrícula em andamento, acompanhe o evolução do seu caso no <a class="ajudaEtapaLink" href="https://idomed.com.br/" target="_blank" noopener noreferrer>Portal do Candidato.</a></span>',
          },
          {
            subtitulo: 'Sou veterano(a)',
          },
          {
            label:
              'Seu acesso permanece liberado entre seus períodos, mas você só será capaz de ver suas disciplinas quando realizar a rematricula.',
          },
          {
            label:
              '<span>Caso você já tenha completado sua rematricula, aguarde até <b>24 horas</b> para atualização na sua Sala de Aula Virtual.</span>',
          },
        ],
      },
      {
        titulo: 'Esqueci minha senha. O que fazer?',
        paragrafo: [
          {
            label:
              '<span>Na página de login da SAVA, clique no botão <a class="ajudaEtapaLink" href="https://passwordreset.microsoftonline.com/" target="_blank" noopener noreferrer>“Esqueci Minha Senha”</a>:</span>',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/idomed/02.png',
                alt: 'Página de login do SAVA',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/idomed/02.png',
                alt: 'Página de login do SAVA',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label: '<span>Informe seu <b>e-mail de estudante</b> e insira os caracteres da imagem.</span>',
          },
          {
            label: 'Feito isso, clique em avançar.',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/idomed/03.png',
                alt: 'Página de indentificação de usuário',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/idomed/03.png',
                alt: 'Página de indentificação de usuário',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label:
              'Na próxima página, escolha por qual meio de contato você deseja receber o código de verificação: e-mail alternativo, SMS ou ligação para o celular.',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/idomed/04.png',
                alt: 'Página de verificação. Escolha do método de contato',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/idomed/04.png',
                alt: 'Página de verificação. Escolha do método de contato',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label: 'Após receber o código, utilize-o para a verificação prosseguir.',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/idomed/05.png',
                alt: 'Página de verificação. Inserir o código recebido',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/idomed/05.png',
                alt: 'Página de verificação. Inserir o código recebido',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label: 'Pronto! Agora é só definir uma nova senha.',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/idomed/06.png',
                alt: 'Página de redefinição de senha',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/idomed/06.png',
                alt: 'Página de redefinição de senha',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
          {
            label:
              'Você também pode alterar a senha do seu e-mail de estudante pelo Portal do Aluno (SIA). Para isso, acesse o SIA com sua matrícula e senha e siga o passo a passo:',
          },
          {
            label: 'Clique no Menu Lateral > E-mail de Estudante > Atualização de Senha e Benefícios > Atualizar Senha',
          },
          {
            label: 'Atualize a senha respeitando os requisitos necessários.',
          },
          {
            imagem: [
              {
                url: '/assets/images/ajuda/idomed/07.gif',
                alt: 'Passo a Passo da atualização de senha pelo SIA',
                breakpoint: 'desktop',
                ariaHidden: true,
              },
              {
                url: '/assets/images/ajuda/idomed/07.gif',
                alt: 'Passo a passo da atualização de senha pelo SIA',
                breakpoint: 'mobile',
                ariaHidden: true,
              },
            ],
          },
        ],
      },
    ],
    conclusao: {
      titulo: 'Ainda precisa de ajuda para acessar?',
      paragrafo: [
        'Entre em contato com um de nossos atendentes no seguinte telefone.',
        '<b>Fale com a gente: 0800 880 6770</b>',
      ],
    },
  };
};

export const AJUDA_LOGIN: AjudaLogin = contruirAjudaLogin();
