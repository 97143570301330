import { LftToast } from '@lift/design-system-react-web/dist/components';
import { useStore } from 'effector-react';
import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { $toastStore, fecharToast } from '../../stores/toast.store';

export const ToastManager: React.FC = () => {
  const { visible, titulo, mensagem, status } = useStore($toastStore);

  const toastRootContainer = document.querySelector('#toast-root');

  useEffect(() => {
    if (visible && toastRootContainer) {
      toastRootContainer.setAttribute(
        'style',
        'position: fixed; top: 0; right: 0; z-index: 9999; pointer-events: all;',
      );
    }
  }, [toastRootContainer, visible]);

  const Component = useCallback(
    () => (
      <LftToast
        message={mensagem}
        status={status}
        visible={visible}
        showtime={5000}
        title={titulo}
        lftClose={() => fecharToast()}
        tabIndex={0}
      />
    ),
    [mensagem, status, titulo, visible],
  );

  if (toastRootContainer) {
    return createPortal(<Component />, toastRootContainer);
  }

  return <Component />;
};
