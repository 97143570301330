import { Strings } from '@aura/core-lib';
import { GetDataElement, GetSubMenusDisciplina, Menu, MenuConfig } from './disciplinas.config.types';

const SUB_MENUS: Array<Menu> = [
  {
    label: 'Minhas Disciplinas',
    path: '/',
  },
  {
    label: 'Formação Socioemocional',
    path: '/formacao-socioemocional',
  },
];

const getDataElement: GetDataElement = (label) => {
  const dataElement = `link ${label}`;
  return Strings.normalize({ target: dataElement }).toLowerCase();
};

export const getSubMenusDisciplinas: GetSubMenusDisciplina = () => {
  const subMenus: Array<MenuConfig> = SUB_MENUS.map((menu) => {
    const { label, path } = menu;
    return {
      label,
      path,
      dataElement: getDataElement(label),
    };
  });

  return subMenus;
};
