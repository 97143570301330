import { Empregabilidade } from '../../types';

export const EMPREGABILIDADE: Empregabilidade[] = [
  {
    title: 'Carreira',
    descricao: 'Carreira',
    url: '/carreiras',
    linkExterno: false,
    dataElement: 'menu-carreiras',
  },
  {
    title: 'Encontre sua vaga',
    url: 'https://www.encontresuavaga.com.br/',
    descricao: 'Encontre sua vaga',
    linkExterno: true,
  },
  {
    title: 'Singular',
    url: '/singular',
    descricao: 'Singular',
    linkExterno: false,
    dataElement: 'menu-singular',
  },
  {
    title: 'Mapeamento Socioemocional',
    url: '/mapeamento-socioemocional',
    descricao: 'Mapeamento Socioemocional',
    linkExterno: false,
    dataElement: 'menu-mapeamento-socioemocional',
  },
];
