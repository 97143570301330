import { Empregabilidade } from '../../types';

export const EMPREGABILIDADE: Empregabilidade[] = [
  {
    title: 'Mapeamento Socioemocional',
    url: '/mapeamento-socioemocional',
    descricao: 'Mapeamento Socioemocional',
    linkExterno: false,
    dataElement: 'menu-mapeamento-socioemocional',
  },
];
